


































































































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ListViewMixin from "@/components/shared/ListViewMixin";
import CurrencyService from "@/services/CurrencyService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import StringUtil from "@/utilities/StringUtil";
import RouteUtil from "@/utilities/RouteUtil";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

@Component({
    components: { AlertDialogView },
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 0,
            recordCount: 10,
            loading: false,
        },
        filter: {
            code: "",
            name: "",
            symbol: ""
        },
        currencies: [],
    }),
})
export default class CustomerGroupListView extends Mixins(ListViewMixin) {
    private alertDialog = new AlertDialogProperties();
    private currencyService = new CurrencyService();

    public get headers() {
        return [
            {
                text: this.$t("text.code"),
                value: "code",
                width: "15%",
            },
            {
                text: this.$t("text.name"),
                value: "name",
            },
            {
                text: this.$t("text.symbol"),
                value: "symbol",
                width: "15%",
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                fixed: true,
                align: "center",
                width: "120px",
                sortable: false,
            },
        ];
    }

    public created() {
        this.listenVisibleChanged();

        const data = this.$data;
        const query = this.$route.query;

        data.table.pageNo = parseInt((query.pageNo as any) ?? 1);
        data.table.recordCount = parseInt((query.recordCount as any) ?? 10);
        data.filter.code = query.code ?? "";
        data.filter.name = query.name ?? "";
        data.filter.symbol = query.symbol ?? "";

        this.load();
    }

    public destroyed() {
        this.removeVisibleChanged();
    }

    public visibleChanged(e) {
        if (!document.hasFocus()) this.load();
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        try {
            const filter = this.$data.filter;
            const r = await this.currencyService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                code: StringUtil.enclose(filter.code, "%", "%"),
                name: StringUtil.enclose(filter.name, "%", "%"),
                symbol: StringUtil.enclose(filter.symbol, "%", "%")
            });
            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            const currencies = r.data.currencies;
            this.$data.currencies = currencies.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }

            const url = RouteUtil.makeUrl("/management/common/currencies", {
                pageNo: this.$data.table.pageNo,
                recordCount: rowCount,
                code: filter.code,
                name: filter.name,
                symbol: filter.symbol
            });
            if (this.$route.fullPath !== url) {
                await this.$router.replace(url);
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public removeDialog(record: any) {
        AlertDialogProperties.delete(this.alertDialog, record.code);
        this.alertDialog.tag = { command: "remove", record };
        this.alertDialog.visible = true;
    }

    public async remove(record: any) {
        try {
            await this.currencyService.delete(record);
            await this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag && tag.command === "remove") {
            if (button === this.$t("text.yes")) {
                this.remove(tag.record);
            }
        }
    }
}
